import clsx from 'clsx'
import { motion, useAnimationControls } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'
import { z } from 'zod'

import { useColorMode, useColorModeValue } from '@/components/colorMode'
import { Loader } from '@/components/feedback'
import { Avatar } from '@/components/media'
import Link from '@/components/TwLink'
import { useDisclosure, useOnPC, useSiteLinks } from '@/hooks'
import { usePersistentState } from '@/hooks/usePersistentState'
import {
  BrightnessUpLine,
  BurgerLine,
  MoonLine,
  SearchLine,
  ShoppingCartLine,
  WalletLine,
} from '@/icons'
import { useAuth } from '@/lib/auth'
import { isNonAnynomousUser } from '@/lib/auth/types/user'
import { ON_PROD } from '@/lib/env'
import { topURL } from '@/lib/url'
import { useNotificationContext } from '@/lib/user/NotificationProvider'

import { IconButton } from '../form'
import { useCart } from '../shoppingCart/CartProvider'
import { GasSwitch } from './GasSwitch'
import { LanguageCurrencySwitch } from './LanguageCurrencySwitch'
import { NavBarDrawer } from './NavBarDrawer'
import { NavBarLinks } from './NavBarLinks'
import { NavBarLogo } from './NavBarLogo'
import { NavBarModal } from './NavBarModal'
import { NavBarSearch, useNavbarSearchVM } from './NavBarSearch'

const CartButton = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const cart = useCart()

  const controls = useAnimationControls()
  const [hasOpened = false, setOpened] = usePersistentState(
    'cart-animation-opened',
    z.boolean(),
  )
  useEffect(() => {
    const openCart = cart.openDrawer
    const sub = cart.animationSubject.subscribe(async () => {
      await controls.start({
        y: [null, 2, -2, 0],
        transition: { duration: 0.7, times: [0, 0.2, 0.3, 0.4] },
      })
      if (hasOpened) return
      openCart()
      setOpened(true)
    })
    return () => sub.unsubscribe()
  }, [controls, cart.animationSubject, cart.openDrawer, hasOpened, setOpened])

  return (
    <motion.div className={clsx(className, 'relative')} animate={controls}>
      <IconButton
        className="h-10 w-10"
        icon={<ShoppingCartLine className="h-6 w-6 text-gray-500" />}
        aria-label={t('Shopping Cart')}
        onClick={cart.openDrawer}
      />
      {cart.itemCount > 0 && (
        <span className="ts-caption-3 absolute top-0 right-0 min-w-[16px] rounded-full bg-primary-4 px-1 text-center font-bold text-button-text">
          {cart.itemCount}
        </span>
      )}
    </motion.div>
  )
}

export const NavBar = () => {
  const { t } = useTranslation()
  const { toggleColorMode } = useColorMode()
  const onPC = useOnPC('lg')
  const text = useColorModeValue(
    t('Switch to dark mode'),
    t('Switch to light mode'),
  )
  const SwitchIcon = useColorModeValue(MoonLine, BrightnessUpLine)
  const menuDisclosure = useDisclosure()
  const drawerDisclosure = useDisclosure()
  const searchVM = useNavbarSearchVM()
  const { user, tryWeb3SignIn, signOut } = useAuth()
  const { offer, activity } = useNotificationContext()
  const showNotificationBadge = offer || activity

  const userIcon = useMemo(() => {
    switch (user._tag) {
      case 'anonymous':
        return (
          <div className="relative flex h-10 w-10 items-center justify-center rounded-full border-2 border-transparent bg-bg bg-clip-padding">
            <span className="absolute inset-0 -z-10 -m-0.5 animate-avatar rounded-full bg-gradient-to-r from-[#3772FF] to-[#9757D7]" />
            <WalletLine className="h-5 w-5 text-gray-500" />
          </div>
        )
      case 'pending':
        return (
          <span className="flex h-10 w-10 items-center justify-center rounded-full border-2">
            <Loader className="h-5 w-5" />
          </span>
        )
      case 'web3-registered':
      case 'registered':
        return <Avatar className="h-10 w-10" data={user.meta} />
    }
  }, [user])

  const links = useSiteLinks()

  return (
    <>
      <nav className="sticky top-0 z-[1100] h-[72px] bg-bg/80 shadow-[0px_1px_0px_rgba(0,0,0,0.05)] backdrop-blur-md dark:shadow-[0px_1px_0px_rgba(255,255,255,0.05)] lg:h-[80px]">
        <div
          className={clsx(
            'flex h-full items-center px-4 lg:px-8',
            searchVM.active
              ? 'space-x-0 md:space-x-6'
              : 'space-x-4 md:space-x-6',
          )}
        >
          <Link
            className={clsx('relative', searchVM.active && 'hidden md:block')}
            hoverStyle="opacity"
            href={topURL}
          >
            <NavBarLogo className="h-8 w-auto" />
            {!ON_PROD && (
              <p className="hidden rounded bg-[#456EF2] px-1 py-0.5 text-xs text-white sm:inline-block">
                Testnet
              </p>
            )}
          </Link>
          <NavBarLinks links={links.market} />
          <div className="hidden flex-1 md:block">
            <NavBarSearch vm={searchVM} />
          </div>
          <div className="flex flex-1 justify-end md:hidden">
            {searchVM.active ? (
              <NavBarSearch vm={searchVM} />
            ) : (
              <IconButton
                className="h-10 w-10"
                icon={<SearchLine className="h-6 w-6 text-gray-500" />}
                aria-label={t('Search')}
                onClick={(e) => {
                  e.stopPropagation()
                  searchVM.setActive(true)
                }}
              />
            )}
          </div>
          {!searchVM.active && <CartButton className="block lg:hidden" />}
          <div className="hidden items-center space-x-3 lg:flex">
            {onPC && ON_PROD && <GasSwitch />}
            <LanguageCurrencySwitch />
            <IconButton
              className="h-10 w-10"
              icon={<SwitchIcon className="h-6 w-6 text-gray-500" />}
              aria-label={text}
              onClick={toggleColorMode}
            />
            <CartButton />
          </div>
          <div
            className={clsx('relative', searchVM.active && 'hidden md:flex')}
          >
            <IconButton
              className="GA-nav-avatar h-12 w-12 outline-none" // TODO: focus ring
              icon={userIcon}
              aria-label={t('Open User Menu')}
              onClick={() => {
                switch (user._tag) {
                  case 'pending':
                    signOut()
                    break
                  case 'anonymous':
                    tryWeb3SignIn()
                    break
                  case 'web3-registered':
                  case 'registered':
                    drawerDisclosure.onToggle()
                    break
                }
              }}
            />
            {showNotificationBadge && (
              <span className="absolute top-1 right-1 h-2 w-2 rounded-full bg-primary-4" />
            )}
          </div>
          <div
            className={clsx('lg:hidden', searchVM.active && 'hidden md:flex')}
          >
            <IconButton
              className="-mr-2 h-12 w-12 text-gray-900"
              icon={<BurgerLine className="h-6 w-6" />}
              aria-label={t('Open Menu')}
              onClick={menuDisclosure.onToggle}
            />
          </div>
        </div>
      </nav>
      <NavBarModal disclosure={menuDisclosure} links={links.market} />
      {isNonAnynomousUser(user) && (
        <NavBarDrawer disclosure={drawerDisclosure} user={user} />
      )}
    </>
  )
}
