import clsx from 'clsx'
import copy from 'copy-to-clipboard'
import { constants } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { pipe } from 'fp-ts/lib/function'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import useSWRImmutable from 'swr/immutable'

import { Avatar } from '@/components/media'
import {
  DrawerContent,
  Modal,
  ModalBody,
  ModalOverlay,
} from '@/components/overlay'
import Link from '@/components/TwLink'
import { usePortfolio } from '@/components/user/PortfolioCtx'
import { UseDisclosureReturn, useModal } from '@/hooks'
import useCurrency from '@/hooks/useCurrency'
import useOptionalTask from '@/hooks/useOptionalTask'
import {
  ArrowRightSquareFilled,
  CloseLine,
  CoinsLine,
  CommentLine,
  CopyLine,
  HeartLine,
  MegaphoneLine,
  ReceiptLine,
  SettingsLine,
  ShopLine,
  SignOutLine,
  SwapLine,
  WalletLine,
} from '@/icons'
import * as api from '@/lib/api'
import { useAuth } from '@/lib/auth'
import { NonAnonymousUser } from '@/lib/auth/types/user'
import { fmtEthAddr, fmtNumber, fmtNumber_Temp } from '@/lib/formatter'
import { O, RD, TE } from '@/lib/fp'
import toast from '@/lib/toast'
import {
  bulkListingURL,
  rewardsURL,
  settingsURL,
  userURL,
  userURLMatcher,
} from '@/lib/url'
import { useNotificationContext } from '@/lib/user/NotificationProvider'
import * as rewardV3 from '@/lib/x2y2/rewaradV3'
import { DEFAULT_NETWORK, getProviderByNetworkId } from '@/utils/network'

import { IconButton } from '../form'
import { TokenIcon } from '../TokenIcon'
import SwapTokenModal from './SwapTokenModal'

type UseRewardsProps = {
  user: NonAnonymousUser
  isActive: boolean
}
const useRewards = ({ user, isActive }: UseRewardsProps) => {
  const [amount, setAmount] = useState(constants.Zero)
  useEffect(() => {
    if (!isActive) return
    const action = async () => {
      try {
        const canClaim = await rewardV3.canClaim(user)
        setAmount(canClaim.claimable)
      } catch (e) {
        console.error(e)
      }
    }
    action()
  }, [user, isActive])
  const rewards = [
    {
      symbol: 'X2Y2',
      digits: 18,
      name: 'X2Y2 Token',
      amount: amount,
    },
  ]
  return { rewards }
}

type Props = {
  disclosure: UseDisclosureReturn
  user: NonAnonymousUser
}
export const NavBarDrawer = ({ disclosure, user }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { signOut } = useAuth()
  const { address } = user.meta
  const { offer, activity } = useNotificationContext()

  const swapTokenModal = useModal(SwapTokenModal)
  const { balance, refresh: refreshPortfolio } = usePortfolio()
  const { rewards } = useRewards({ user, isActive: disclosure.isOpen })
  const { fmtCoinNumber } = useCurrency()

  useEffect(() => {
    if (disclosure.isOpen) {
      refreshPortfolio()
    }
  }, [disclosure.isOpen, refreshPortfolio])

  const fetchUnreadMessageTask = useMemo(() => {
    if (!disclosure.isOpen) return O.none
    return pipe(
      api.addressUnreadMessageCount({
        address: user.meta.address,
      }),
      TE.map((a) => a > 0),
      O.some,
    )
  }, [disclosure.isOpen, user.meta.address])
  const [hasUnread] = useOptionalTask(fetchUnreadMessageTask, {
    skipPending: true,
  })

  const { data: ensName } = useSWRImmutable(
    { key: 'ens-lookup', address },
    async ({ address }) => {
      const provider = getProviderByNetworkId(DEFAULT_NETWORK)
      const name = await provider.lookupAddress(address)
      return name
    },
  )

  const links = [
    {
      title: t('My Items'),
      icon: WalletLine,
      href: userURL(address, { tab: 'items' }),
      match: userURLMatcher(address, { tab: 'items' }),
      gaLabel: 'GA-sidebar-my-items',
    },
    {
      title: t('Favorites'),
      icon: HeartLine,
      href: userURL(address, { tab: 'favorites' }),
      match: userURLMatcher(address, { tab: 'favorites' }),
      gaLabel: 'GA-sidebar-favorites',
    },
    {
      title: t('Offers'),
      icon: MegaphoneLine,
      href: userURL(address, { tab: 'offers' }),
      match: userURLMatcher(address, { tab: 'offers' }),
      dot: offer,
      gaLabel: 'GA-sidebar-offers',
    },
    {
      title: t('Activities'),
      icon: ReceiptLine,
      href: userURL(address, { tab: 'activities' }),
      match: userURLMatcher(address, { tab: 'activities' }),
      dot: activity,
      gaLabel: 'GA-sidebar-activities',
    },
    {
      title: t('Bulk Listing'),
      icon: ShopLine,
      href: bulkListingURL,
      match: /^\/tools\/bulk-listing$/,
      gaLabel: 'GA-sidebar-bulk-listing',
    },
    {
      title: t('Settings'),
      icon: SettingsLine,
      href: settingsURL('profile'),
      match: /^\/settings\/profile$/,
      gaLabel: 'GA-sidebar-settings',
    },
    {
      title: t('Chat'),
      icon: CommentLine,
      href: 'https://chat.blockscan.com/',
      isExternal: true,
      dot: RD.isSuccess(hasUnread) && hasUnread.value,
      gaLabel: 'GA-sidebar-chat',
    },
  ]

  return (
    <>
      <Modal {...disclosure} motionPreset="slide">
        <ModalOverlay blur />
        <DrawerContent className="sm:max-w-[375px]">
          <header className="flex justify-end p-6 pb-3">
            <IconButton
              className="h-12 w-12 rounded-full"
              variant="ghost"
              icon={<CloseLine className="h-6 w-6" />}
              aria-label={t('Close Menu')}
              onClick={disclosure.onClose}
            />
          </header>
          <ModalBody className="pb-10">
            <div className="flex items-center">
              <Avatar className="h-10 w-10" data={user.meta} />
              <div className="ml-4 flex flex-col">
                <p className="ts-headline-6">
                  {user.meta.nickname ?? ensName ?? fmtEthAddr(address)}
                </p>
                <div
                  className="ts-caption-3 mt-0.5 flex cursor-pointer items-center font-medium text-gray-500"
                  onClick={() => {
                    copy(address)
                    toast({
                      status: 'success',
                      title: t('Copied!'),
                    })
                  }}
                >
                  <span>{fmtEthAddr(address)}</span>
                  <CopyLine className="ml-1 h-3 w-3" />
                </div>
              </div>
              <IconButton
                className="ml-auto h-12 w-12 rounded-full"
                variant="outline"
                icon={<SignOutLine className="h-6 w-6 rotate-180" />}
                aria-label={t('Logout')}
                onClick={() => {
                  disclosure.onClose()
                  signOut()
                }}
              />
            </div>
            <div className="mt-6 grid grid-cols-2 gap-y-2">
              {links.map((a, i) => {
                const active = a.match?.test(router.asPath)
                return (
                  <Link
                    key={i}
                    className={clsx(
                      a.gaLabel,
                      'flex items-center space-x-2.5 rounded-full px-4 py-3 hover:bg-modal-section-bg hover:no-underline',
                      active ? 'text-gray-800' : 'text-gray-500',
                    )}
                    href={a.href}
                    isExternal={a.isExternal ?? false}
                  >
                    <a.icon className="h-6 w-6" />
                    <span className="ts-button-2">{a.title}</span>
                    {a.dot && <span className="h-2 w-2 rounded bg-primary-4" />}
                  </Link>
                )
              })}
            </div>
            {/* TODO: Logic */}
            <div className="mt-10 hidden rounded-xl bg-emerald-50 p-3">
              <p className="ts-caption text-gray-500 [&_strong]:text-gray-900">
                <Trans t={t}>
                  There are <strong>{{ amount: fmtNumber(1000) }} X2Y2</strong>{' '}
                  tokens waiting for you without <strong>expiration</strong>.
                </Trans>
              </p>
              <button className="mt-3 flex items-center py-1 text-primary-4 hover:opacity-50">
                <ArrowRightSquareFilled className="h-[1em] w-[1em]" />
                <span className="ts-button-2 ml-1">{t('Claim')}</span>
              </button>
            </div>
            <div className="mt-10 rounded-xl border border-gray-300 px-4 pt-3.5 pb-3">
              <div className="flex flex-row border-b border-gray-300 pb-3 leading-6">
                <div className="ts-hairline-2 flex-1 font-bold">
                  {t('Wallet')}
                </div>
                <div
                  className="ts-button-2 flex cursor-pointer flex-row justify-center text-primary-1 hover:underline"
                  onClick={() =>
                    swapTokenModal.onOpen({
                      title: t('Swap'),
                      output: 'WETH',
                      onSuccess: refreshPortfolio,
                    })
                  }
                >
                  <div className="mr-1 h-4 w-4">
                    <SwapLine className="h-[1em] w-[1em]" />
                  </div>
                  <div>{t('Swap ETH / WETH')}</div>
                </div>
              </div>
              {balance.map((e) => (
                <div
                  key={e.symbol}
                  className="flex flex-row justify-center py-2.5"
                >
                  <TokenIcon
                    symbol={e.symbol}
                    className="h-8 w-8 self-center"
                  />
                  <div className="ml-3 flex flex-1 flex-col">
                    <div className="ts-body-2 font-bold">{e.symbol}</div>
                    <div className="ts-caption-2 flex flex-col text-gray-500">
                      {e.name}
                    </div>
                  </div>
                  <div className="ml-3 flex flex-col text-right">
                    <div className="ts-body-2 font-bold">
                      {fmtNumber_Temp(
                        parseFloat(formatUnits(e.balance, e.digits)),
                      )}
                    </div>
                    <div className="ts-caption-2 flex flex-col text-gray-500">
                      {fmtCoinNumber(
                        parseFloat(formatUnits(e.balance, e.digits)),
                        e.symbol,
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-10 rounded-xl border border-gray-300 px-4 pt-3.5 pb-3">
              <div className="flex border-b border-gray-300 pb-3 leading-6">
                <div className="ts-hairline-2 flex-1 font-bold">
                  {t('Rewards')}
                </div>
                <Link
                  className="ts-button-2 flex justify-center text-primary-1"
                  href={rewardsURL({})}
                >
                  <CoinsLine className="mr-1 h-4 w-4" />
                  <span>{t('Collect')}</span>
                </Link>
              </div>
              {rewards.map((e) => (
                <div key={e.symbol} className="flex justify-center py-2.5">
                  <TokenIcon
                    symbol={e.symbol}
                    className="h-8 w-8 self-center"
                  />
                  <div className="ml-3 flex flex-1 flex-col">
                    <div className="ts-body-2 font-bold">{e.symbol}</div>
                    <div className="ts-caption-2 flex flex-col text-gray-500">
                      {e.name}
                    </div>
                  </div>
                  <div className="ml-3 flex flex-col text-right">
                    <div className="ts-body-2 font-bold">
                      {e.amount.lt(0)
                        ? '-'
                        : fmtNumber_Temp(
                            parseFloat(formatUnits(e.amount, e.digits)),
                          )}
                    </div>
                    <div className="ts-caption-2 flex flex-col text-gray-500">
                      {e.amount.lt(0)
                        ? '-'
                        : fmtCoinNumber(
                            parseFloat(formatUnits(e.amount, e.digits)),
                            e.symbol,
                          )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </DrawerContent>
      </Modal>
      {swapTokenModal.component}
    </>
  )
}
