import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

type TabProps = {
  className?: string
  children: ReactNode
}
export const TabList = ({ children, className }: TabProps) => (
  <Tab.List className="overflow-x-auto overflow-y-hidden">
    <div
      className={clsx(
        className,
        'flex w-full min-w-max space-x-1 border-b border-gray-300 lg:space-x-2',
      )}
    >
      {children}
    </div>
  </Tab.List>
)

type TabButtonProps = {
  children: ReactNode
  disabled?: boolean
  compact?: boolean
}
export const TabButton = ({
  children,
  disabled,
  compact = false,
}: TabButtonProps) => (
  <Tab
    className={clsx(
      'ts-button-2 relative shrink-0 px-3 py-2 text-gray-500 hover:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent ui-selected:text-gray-900',
      !compact && 'lg:ts-button-1 lg:px-4 lg:py-3',
    )}
    disabled={disabled}
  >
    {({ selected }) => (
      <>
        {children}
        {selected && (
          <div className="absolute inset-x-0 -bottom-px h-0.5 bg-gray-700" />
        )}
      </>
    )}
  </Tab>
)

type Option<A extends string> = {
  key: A
  title: string
}

type Props<A extends string> = {
  options: Option<A>[]
  selected: A
  onSelect: (selected: A) => void
}
export const Tabs = <A extends string>({
  options,
  selected,
  onSelect,
}: Props<A>) => (
  <Tab.Group
    selectedIndex={options.findIndex((o) => o.key === selected)}
    onChange={(idx) => onSelect(options[idx].key)}
  >
    <TabList>
      {options.map(({ title }, idx) => (
        <TabButton key={idx}>{title}</TabButton>
      ))}
    </TabList>
  </Tab.Group>
)

export default Tabs
