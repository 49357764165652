import clsx from 'clsx'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

import { NavBarLink } from '@/components/navbar/NavBarLinks'
import Link from '@/components/TwLink'
import { pathMatched } from '@/hooks'
import { isExternal } from '@/lib/url'

type LinkOrGroup =
  | { type: 'link'; link: NavBarLink }
  | { type: 'group'; title: string; links: NavBarLink[] }

type Props = {
  className?: string
  links: LinkOrGroup[]
  onClose: () => void
}

const MenuLink = ({
  link: b,
  onClose,
}: {
  link: NavBarLink
  onClose: () => void
}) => {
  const router = useRouter()
  return (
    <Link
      className={clsx(
        b.gaLabel,
        'ts-button-1 py-4 pl-12 hover:bg-modal-section-bg hover:no-underline',
        b.match?.test(router.asPath) ? null : 'text-gray-500',
      )}
      isExternal={isExternal(b.href)}
      href={b.href}
      onClick={onClose}
    >
      {b.title}
    </Link>
  )
}

export const NavBarMenu = ({ className, links, onClose }: Props) => {
  const router = useRouter()
  return (
    <div className={className}>
      {links.map((a, i) =>
        a.type === 'link' ? (
          <Link
            className={clsx(
              'ts-headline-6 px-8 py-3 hover:bg-modal-section-bg hover:no-underline',
              a.link.match?.test(router.asPath) ? undefined : 'text-gray-500',
            )}
            href={a.link.href}
            onClick={onClose}
            key={i}
          >
            {a.link.title}
          </Link>
        ) : (
          <Fragment key={i}>
            <button
              className={clsx(
                'w-full px-8 py-3 text-start',
                pathMatched(router.asPath, [a.links])
                  ? 'text-gray-700'
                  : 'text-gray-500',
              )}
            >
              <span className="ts-headline-6 truncate">{a.title}</span>
            </button>
            <div className="flex flex-col">
              {a.links.map((b, i) => (
                <MenuLink link={b} onClose={onClose} key={i} />
              ))}
            </div>
          </Fragment>
        ),
      )}
    </div>
  )
}
