import clsx from 'clsx'
import React from 'react'

import { ReactComponent as Logo } from '@/images/Logo.svg'

type Props = {
  className?: string
}
export const NavBarLogo = ({ className }: Props) => {
  // Seems no longer effective
  // const fill = useColorModeValue('url(#paint0)', 'url(#paint1)')
  return (
    <Logo
      className={clsx(className, 'inline-block text-gray-900')}
      // css={{ '.logo-path': { fill } }}
    />
  )
}
