import clsx from 'clsx'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

import Link from '@/components/TwLink'
import { pathMatched } from '@/hooks'

export type NavBarLink = {
  title: string
  href: string
  match?: RegExp
  gaLabel?: string
  onClick?: () => void
  featurePromptCardRenderFunc?: (a: ReactElement) => ReactNode
}

type Props = {
  links: NavBarLink[]
}
export const NavBarLinks = ({ links }: Props) => {
  const router = useRouter()

  return (
    <div className="hidden items-center font-bold lg:flex">
      {links.map((a, i) => {
        const link = (
          <Link
            className={clsx(
              a.gaLabel,
              'ts-button-1 rounded-full px-6 py-4 hover:bg-modal-section-bg hover:no-underline',
              !pathMatched(router.asPath, [[a]]) && 'text-gray-500',
            )}
            href={a.href}
            onClick={a.onClick}
            key={i}
          >
            {a.title}
          </Link>
        )

        return a.featurePromptCardRenderFunc?.(link) ?? link
      })}
    </div>
  )
}
