import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

import { useColorMode, useColorModeValue } from '@/components/colorMode'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { UseDisclosureReturn, useOnPC } from '@/hooks'
import { BrightnessUpLine, MoonLine } from '@/icons'

import { IconButton } from '../form'
import { LanguageCurrencySwitch } from './LanguageCurrencySwitch'
import { NavBarLink } from './NavBarLinks'
import { NavBarMenu } from './NavBarMenu'

type Props = {
  disclosure: UseDisclosureReturn
  links: NavBarLink[]
}
export const NavBarModal = ({ disclosure, links }: Props) => {
  const { t } = useTranslation()
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue(
    t('Switch to dark mode'),
    t('Switch to light mode'),
  )
  const SwitchIcon = useColorModeValue(MoonLine, BrightnessUpLine)

  // Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
  // Result: We'll close the menu.
  const onPC = useOnPC('lg')
  const { onClose } = disclosure
  useEffect(() => {
    if (onPC) {
      onClose()
    }
  }, [onPC, onClose])

  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent size="full">
        <ModalHeader className="sticky top-0 !mt-0 flex h-[96px] items-center bg-modal-bg !py-0">
          <LanguageCurrencySwitch className="mr-4" isMobile />
          <IconButton
            className="h-12 w-12 rounded-full"
            variant="outline"
            icon={<SwitchIcon className="h-6 w-6 text-gray-500" />}
            aria-label={text}
            onClick={toggleColorMode}
          />
          <ModalCloseButton
            className="!-right-2 !p-3"
            title={t('Close Menu')}
          />
        </ModalHeader>
        <ModalBody className="!p-0">
          <NavBarMenu
            className="mb-10"
            links={[
              { type: 'group', title: t('Marketplace'), links },
              // { type: 'group', title: t('Resources'), links: toolLinks },
            ]}
            onClose={disclosure.onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
