import { Trans, useTranslation } from 'next-i18next'

import { InfoCircleFilled } from '@/icons'
import { ON_PROD, ON_STAG } from '@/lib/env'

export const ZeroFeeBanner = () => {
  const { t } = useTranslation()
  if (ON_PROD) return null
  return (
    <div className="flex items-center bg-gradient-to-r from-[#3772FF] to-[#9757D7] py-3 px-3 text-sm text-button-text lg:px-6">
      <div className="flex flex-1 items-center">
        <InfoCircleFilled className="mr-3 hidden h-5 w-5 lg:flex" />
        {/* NOTE: Extra text-button-text here to enable opacity */}
        <div className="text-button-text text-opacity-80">
          <Trans t={t}>
            You are on the X2Y2 Goerli test network (
            {{ site: ON_STAG ? 'staging.x2y2.io' : 'goerli.x2y2.io' }})
          </Trans>
        </div>
      </div>
      {/* <a
        className="ts-button-2 ml-2 flex items-center space-x-1 justify-self-end"
        href={tokenomics2URL}
        target="_blank"
        rel="noreferrer"
      >
        <ArrowRightSquareFilled />
        <span>{t('More')}</span>
      </a> */}
    </div>
  )
}
