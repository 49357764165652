import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

import { Tooltip } from '@/components/overlay'
import { VerifiedFilled } from '@/icons'

type Props = {
  className: string
}
const UserVerifiedBadge = ({ className }: Props) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      label={t(
        'This account is verified because it’s notable in art, entertainment, or another designated category.',
      )}
      placement="top"
      hasArrow
    >
      <VerifiedFilled className={clsx(className, 'text-primary-1')} />
    </Tooltip>
  )
}

export default UserVerifiedBadge
