import { FloatingPortal } from '@floating-ui/react'
import { Popover, Tab } from '@headlessui/react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import Link from '@/components/TwLink'
import useCurrency, { fmtCurrency } from '@/hooks/useCurrency'
import { usePopover } from '@/hooks/usePopover'
import { GlobeLine } from '@/icons'
import { Currency } from '@/lib/currency'

import { IconButton } from '../form'
import { TabButton, TabList } from '../Tabs'

// update locale in useDayjs when changes happen
const locales = [
  'en',
  // 'es',
  'fr',
  // 'pt',
  // 'ru',
  'ja',
  'zh-Hans',
  'zh-Hant',
  'ko',
  // 'vi',
  // 'id',
  // 'th',
] as const
export type Locale = (typeof locales)[number]
const localeTitles: Record<Locale, string> = {
  en: 'English',
  // es: 'Español',
  fr: 'Français',
  ja: '日本語',
  // pt: 'Português',
  // ru: 'Русский',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁体中文',
  ko: '한국어',
  // vi: 'Tiếng Việt',
  // id: 'Indonesian',
  // th: 'ภาษาไทย',
}
const currencies: Currency[] = [
  'USD',
  'CNY',
  'JPY',
  'KRW',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'RUB',
]

type Props = {
  className?: string
  isMobile?: boolean
}
export const LanguageCurrencySwitch = ({
  className,
  isMobile = false,
}: Props): JSX.Element => {
  const router = useRouter()
  const { t, i18n } = useTranslation()
  const { currency, setCurrency } = useCurrency()
  const title = `${i18n.language.toUpperCase()}・${currency}`

  const { referenceProps, floatingProps } = usePopover({ offset: 30 })

  return (
    <Popover>
      <Popover.Button
        as={IconButton}
        className={clsx(className, 'h-12 w-12 rounded-full lg:h-10 lg:w-10')}
        variant={isMobile ? 'outline' : undefined}
        {...referenceProps}
        icon={<GlobeLine className="h-6 w-6 text-gray-500" />}
        aria-label={title}
      />
      <FloatingPortal>
        <Popover.Panel
          className="z-[1500] overflow-y-auto rounded-xl bg-modal-bg px-4 pt-5 shadow-light-1 dark:shadow-dark-1"
          {...floatingProps}
        >
          <Tab.Group>
            <TabList className="justify-center">
              <TabButton compact>{t('Language')}</TabButton>
              <TabButton compact>{t('Currency')}</TabButton>
            </TabList>
            <Tab.Panels>
              <Tab.Panel className="py-4">
                <div className="grid grid-cols-2">
                  {locales.map((locale) => (
                    <Link
                      className={clsx(
                        'ts-button-2 flex h-14 w-[124px] items-center rounded-full px-4 hover:bg-modal-section-bg',
                        locale === i18n.language
                          ? 'text-gray-800'
                          : 'text-gray-500',
                      )}
                      hoverStyle="none"
                      href={router.asPath}
                      locale={locale}
                      key={locale}
                    >
                      <span className="truncate">{localeTitles[locale]}</span>
                    </Link>
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel className="py-4">
                <div className="grid grid-cols-2">
                  {currencies.map((c) => (
                    <Popover.Button
                      className={clsx(
                        'ts-button-2 flex h-14 w-[124px] items-center rounded-full px-4 hover:bg-modal-section-bg',
                        c === currency ? 'text-gray-800' : 'text-gray-500',
                      )}
                      onClick={() => setCurrency(c)}
                      key={c}
                    >
                      <span className="truncate">
                        {c} - {fmtCurrency(c)}
                      </span>
                    </Popover.Button>
                  ))}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Popover.Panel>
      </FloatingPortal>
    </Popover>
  )
}
