import useSWR from 'swr'

import { GasLine } from '@/icons'
import { http } from '@/lib/http'

type GasResp = {
  safe: number
  fast: number
  normal: number
  time: number
}

export const GasSwitch = () => {
  const { data } = useSWR(
    '/uniapi/eth/gas',
    (url) =>
      http.get<GasResp>(url, {
        baseURL: process.env.NEXT_PUBLIC_X2Y2_API_BASE_URL,
      }),
    { refreshInterval: 15_000 },
  )
  if (!data) return null
  const r = data.data
  return (
    <button
      className="ts-button-1 flex h-10 items-center space-x-2 px-2 text-gray-500"
      disabled
    >
      <GasLine className="h-6 w-6" />
      <span>{r.normal.toFixed(0)}</span>
    </button>
  )
}
