import { SupportedLocale, SwapWidget, TokenInfo } from '@uniswap/widgets'
import { useTranslation } from 'next-i18next'

import { useColorModeValue } from '@/components/colorMode'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@/components/overlay'
import { NETWORK_ETH, NETWORK_GOERLI } from '@/consts'
import meta from '@/data/meta'
import { UseDisclosureReturn } from '@/hooks'
import { WETH_CONTRACT_MAPS, X2Y2_TOKEN_CONTRACT_MAPS } from '@/lib/x2y2'
import { DEFAULT_NETWORK } from '@/utils/network'

import { Locale } from './LanguageCurrencySwitch'

// Source: https://api.coinmarketcap.com/data-api/v3/uniswap/all.json
const TOKEN_LIST = meta.siteUrl + '/uniswap.json'
const UNI_TOKEN_CONTRACT_GOERLI = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
const TOKEN_LIST_GOERLI: TokenInfo[] = [
  {
    chainId: NETWORK_GOERLI,
    name: 'WETH',
    address: WETH_CONTRACT_MAPS[NETWORK_GOERLI],
    decimals: 18,
    symbol: 'WETH',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png',
  },
  {
    chainId: NETWORK_GOERLI,
    name: 'Uniswap',
    address: UNI_TOKEN_CONTRACT_GOERLI,
    decimals: 18,
    symbol: 'UNI',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png',
  },
]

type OutputToken = 'X2Y2' | 'WETH'
const outputMap: Record<number, Record<OutputToken, string>> = {
  [NETWORK_ETH]: {
    WETH: WETH_CONTRACT_MAPS[NETWORK_ETH],
    X2Y2: X2Y2_TOKEN_CONTRACT_MAPS[NETWORK_ETH],
  },
  [NETWORK_GOERLI]: {
    WETH: WETH_CONTRACT_MAPS[NETWORK_GOERLI],
    // No X2Y2 on goerli, so we use UNI instead
    X2Y2: UNI_TOKEN_CONTRACT_GOERLI,
  },
}

const localeMap: Record<Locale, SupportedLocale> = {
  fr: 'fr-FR',
  en: 'en-US',
  ja: 'ja-JP',
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
  ko: 'ko-KR',
}

export type SwapTokenProps = {
  title: string
  output: OutputToken
  onSuccess?: () => void
}
type Props = SwapTokenProps & {
  disclosure: UseDisclosureReturn
}
const SwapTokenModal = ({ disclosure, title, output, onSuccess }: Props) => {
  const { i18n } = useTranslation()
  const isLight = useColorModeValue(true, false)
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="!p-4 lg:!p-6">
          <SwapWidget
            width="auto"
            theme={{
              accent: '#3B82F6',
              container: isLight ? '#FCFCFD' : 'rgb(35,38,47)',
              module: isLight ? '#F4F5F6' : 'rgb(53,57,69)',
              // NOTE: light:gray-300, dark:gray-400
              interactive: isLight ? '#E6E8EC' : 'rgb(81,85,98)',
              outline: 'transparent',
              dialog: isLight ? '#F4F5F6' : 'rgb(53,57,69)',
              fontFamily: 'inherit',
              onAccent: '#FCFCFD',
              primary: isLight ? '#141416' : 'rgb(252,252,253)',
              secondary: isLight ? '#777E90' : 'rgb(119,126,144)',
              hint: isLight ? '#777E90' : 'rgb(119,126,144)',
              onInteractive: isLight ? '#353945' : 'rgb(230,232,236)',
              active: '#3B82F6',
              success: '#58BD7D',
              warning: '#FF6838',
              error: '#FF6838',
              currentColor: 'currentColor',
              tokenColorExtraction: false,
            }}
            tokenList={
              DEFAULT_NETWORK === NETWORK_ETH ? TOKEN_LIST : TOKEN_LIST_GOERLI
            }
            defaultOutputTokenAddress={outputMap[DEFAULT_NETWORK][output]}
            locale={localeMap[i18n.language as Locale]} // TODO: not working
            onTxSuccess={onSuccess}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SwapTokenModal
