import clsx from 'clsx'
import { PropsWithChildren } from 'react'

import { ZeroFeeBanner } from '@/components/homepage/ZeroFeeBanner'
import { UserProvider } from '@/lib/auth'

import Footer from './Footer'
import { NavBar } from './navbar'

export const PageContainer = ({
  className,
  children,
  hideFooter = false,
}: PropsWithChildren<{ className?: string; hideFooter?: boolean }>) => (
  <>
    <NavBar />
    <ZeroFeeBanner />
    <div className={clsx('flex flex-1 flex-col', className)}>{children}</div>
    {!hideFooter && <Footer />}
  </>
)

export const UserPageContainer = ({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <PageContainer className={className}>
      <UserProvider>{children}</UserProvider>
    </PageContainer>
  )
}
