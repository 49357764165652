import { BigNumber, constants, PayableOverrides, utils } from 'ethers'

import { RewardDistributorV3__factory } from '@/lib/contract'
import { http } from '@/lib/http'
import { X2Y2_REWARD_V3_CONTRACT_MAPS } from '@/lib/x2y2'

import { NonAnonymousUser, RegisteredUser } from '../auth/types/user'
import { MarketError } from '../market'
import * as Err from '../market/errors'
import { Signature } from './ilo'
import {
  handleX2Y2Resp,
  invalidAddress,
  parseVolNumber,
  x2y2RequestConfig,
} from './utils'

type ClaimSigned = Signature & {
  deadline: number
  rewards: string
}

type TradingRewards = {
  can_claim_x2y2: string
  pending_claim_x2y2: string
}

const claimSign = async (
  address: string,
  token: string,
): Promise<ClaimSigned | undefined> => {
  try {
    const resp = await http.post(
      '/api/trading/sign',
      { address },
      x2y2RequestConfig(token),
    )
    handleX2Y2Resp(resp.data, Err.ERR_0016)
    if (resp.status === 200 && resp.data.success) {
      return resp.data.data as ClaimSigned
    }
  } catch (e) {
    if (e instanceof MarketError) {
      throw e
    }
  }
  return undefined
}

const rewardsTotal = async (address: string): Promise<TradingRewards> => {
  try {
    const resp = await http.get(
      `/api/trading/rewards?address=${address}`,
      x2y2RequestConfig(null),
    )
    if (resp.status === 200 && resp.data.success) {
      return resp.data.data.rewards as TradingRewards
    }
  } catch (ignored) {}
  return {
    can_claim_x2y2: '0',
    pending_claim_x2y2: '0',
  }
}

export const canClaim = async (
  user: NonAnonymousUser,
): Promise<{
  claimable: BigNumber
  pending: BigNumber
  claimed: BigNumber
}> => {
  const provider = user.web3Provider
  const signer = provider.getSigner()
  const networkId = provider.network.chainId
  const rewardContract = X2Y2_REWARD_V3_CONTRACT_MAPS[networkId]
  let claimable: BigNumber = constants.Zero
  let pending: BigNumber = constants.Zero
  let claimed: BigNumber = constants.Zero
  if (!invalidAddress(rewardContract)) {
    try {
      const address = await signer.getAddress()
      const reward = RewardDistributorV3__factory.connect(
        rewardContract,
        signer,
      )
      const rewards = await rewardsTotal(address)
      claimable = claimable.add(parseVolNumber(rewards.can_claim_x2y2))
      pending = pending.add(parseVolNumber(rewards.pending_claim_x2y2))
      const userClaimed = await reward.userClaimedTotal(address)
      claimed = claimed.add(userClaimed)
      claimable = claimable.sub(claimed)
    } catch (ignored) {}
  }
  return { claimable, pending, claimed }
}

export const claim = async (
  user: RegisteredUser,
  staking: boolean,
): Promise<string> => {
  const provider = user.web3Provider
  const networkId = provider.network.chainId
  const rewardContract = X2Y2_REWARD_V3_CONTRACT_MAPS[networkId]
  if (invalidAddress(rewardContract)) {
    throw new MarketError(Err.ERR_0000)
  }
  const signer = provider.getSigner()
  const address = await signer.getAddress()
  const reward = RewardDistributorV3__factory.connect(rewardContract, signer)
  const signedData = await claimSign(address, user.token)
  if (signedData) {
    const options: PayableOverrides = {}
    try {
      const gasLimit = await reward.estimateGas.claim(
        signedData.deadline,
        parseVolNumber(signedData.rewards),
        staking,
        signedData.v,
        utils.hexZeroPad(signedData.r, 32),
        utils.hexZeroPad(signedData.s, 32),
      )
      options.gasLimit = staking ? gasLimit.mul(3).div(2) : gasLimit
    } catch (ignored) {
      // console.log(ignored)
    }
    const tx = await reward.claim(
      signedData.deadline,
      parseVolNumber(signedData.rewards),
      staking,
      signedData.v,
      utils.hexZeroPad(signedData.r, 32),
      utils.hexZeroPad(signedData.s, 32),
      options,
    )
    await tx.wait()
    return tx.hash
  } else {
    throw new MarketError(Err.ERR_0016)
  }
}
