import { pipe } from 'fp-ts/lib/function'
import { useTranslation } from 'next-i18next'
import router from 'next/router'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Empty } from '@/components/feedback'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@/components/form'
import {
  Avatar,
  Img,
  metadataAssetsAsNftImageProps,
  NftImage,
} from '@/components/media'
import UserVerifiedBadge from '@/components/user/UserVerifiedBadge'
import useOptionalTask from '@/hooks/useOptionalTask'
import { CloseLine, HeartFilled, SearchLine, VerifiedFilled } from '@/icons'
import { useUFO } from '@/images/UFOImage'
import * as api from '@/lib/api'
import { getUserId, useAuth } from '@/lib/auth'
import { resizedCdnUrl } from '@/lib/cdn'
import { fmtEthAddr } from '@/lib/formatter'
import { E, O, RD } from '@/lib/fp'
import { ContractInfoInSearchResultFragment } from '@/lib/gql/types'
import { itemDisplayName } from '@/lib/nft'
import {
  extractNftMetadata,
  extractNftMetadataAssets,
} from '@/lib/nft/metadata'
import { SearchByKeywordResp } from '@/lib/search'
import { recentlySearchedCollections as recentlySearchedCollectionsStore } from '@/lib/store'
import { collectionURL, detailURL, userURL } from '@/lib/url'
import { toChecksumAddress } from '@/lib/web3'
import { DEFAULT_NETWORK } from '@/utils/network'

export const useNavbarSearchVM = () => {
  const [active, _setActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const setActive = useCallback(
    (next: boolean) => {
      _setActive(next)
      if (next) {
        setTimeout(() => {
          inputRef.current?.focus()
        }, 200)
      }
    },
    [_setActive],
  )
  useEffect(() => {
    const handler = () => {
      setActive(false)
    }
    document.addEventListener('click', handler)
    return () => document.removeEventListener('click', handler)
  }, [setActive])

  const { user } = useAuth()
  const likedContractsTask = useMemo(() => {
    if (!active) return O.none
    return pipe(
      getUserId(user),
      O.map((userId) => api.likedContracts({ userId })),
    )
  }, [active, user])
  const [likedContractsResp] = useOptionalTask(likedContractsTask, {
    resetOnNone: true,
    skipPending: true,
  })
  const likedContractIds = useMemo(() => {
    if (RD.isSuccess(likedContractsResp)) {
      return new Set(
        likedContractsResp.value.user_like_nft_contract.map(
          (a) => a.contract.id,
        ),
      )
    }
    return new Set()
  }, [likedContractsResp])

  return { active, setActive, likedContractsResp, likedContractIds, inputRef }
}

type ContractOfContractItem = Pick<
  ContractInfoInSearchResultFragment,
  'network_id' | 'id' | 'icon_url' | 'contract' | 'verified' | 'name' | 'slug'
> & {
  stats?:
    | {
        total_supply?: number | null | undefined
      }
    | null
    | undefined
}

// TODO: Rewrite using headless-ui to resolve various issues...
export const NavBarSearch = ({
  vm,
}: {
  vm: ReturnType<typeof useNavbarSearchVM>
}) => {
  const { t } = useTranslation()

  const [text, setText] = useState('')
  const { active, setActive, likedContractsResp, likedContractIds } = vm

  const suggestionsTask = useMemo(
    () =>
      pipe(
        O.some(text),
        O.filter((a) => a.trim() !== ''),
        O.map((keyword) => api.searchByKeyword({ keyword })),
      ),
    [text],
  )
  const [suggestionsResp] = useOptionalTask(suggestionsTask, {
    resetOnNone: true,
    skipPending: true,
  })

  const getCollectionURL = useCallback(
    (
      contract: Pick<ContractInfoInSearchResultFragment, 'slug' | 'contract'>,
    ) => {
      return collectionURL({
        slug: contract.slug ?? toChecksumAddress(contract.contract),
        tab: 'items',
      })
    },
    [],
  )

  const recentlySearchedCollections = useMemo(
    () =>
      pipe(
        recentlySearchedCollectionsStore.get(),
        O.fromEither,
        O.chain((a) => O.fromNullable(a[DEFAULT_NETWORK])),
        O.getOrElseW(() => []),
      ),
    [],
  )

  const saveToRecentlySearchedCollections = useCallback(
    (contract: ContractOfContractItem) => {
      const allCollections = recentlySearchedCollectionsStore.get()

      recentlySearchedCollectionsStore.set({
        ...pipe(
          allCollections,
          E.getOrElseW(() => ({})),
        ),
        [contract.network_id]: [
          {
            network_id: contract.network_id,
            id: contract.id,
            icon_url: contract.icon_url ?? null,
            contract: contract.contract,
            verified: contract.verified,
            stats: {
              total_supply: contract.stats?.total_supply ?? 0,
            },
            name: contract.name,
            slug: contract.slug ?? null,
          },
          ...pipe(
            allCollections,
            O.fromEither,
            O.chain((a) => O.fromNullable(a[contract.network_id])),
            O.getOrElseW(() => []),
          ).filter((a) => a.id !== contract.id),
        ].slice(0, 12),
      })()
    },
    [],
  )

  return (
    <div
      className="static flex-1 sm:relative"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center space-x-4">
        <InputGroup className="flex-1">
          <InputLeftElement>
            <SearchLine className="h-6 w-6 text-gray-400" />
          </InputLeftElement>
          <Input
            ref={vm.inputRef}
            className="GA-search-bar !rounded-full px-10"
            variant="filled"
            placeholder={t('Search')}
            spellCheck={false}
            value={text}
            onChange={(e) => {
              setText(e.target.value)
              setActive(true)
            }}
            onFocus={() => setActive(true)}
          />
          {text !== '' && (
            <InputRightElement isInteractable>
              <CloseLine
                className="h-5 w-5 cursor-pointer text-gray-700"
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setText('')
                  setActive(true)
                }}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <span
          className="ts-button-2 block shrink-0 cursor-pointer text-gray-700 md:hidden"
          onClick={() => setActive(false)}
        >
          {t('Cancel')}
        </span>
      </div>
      {active &&
        (text.trim() === '' ? (
          (RD.isSuccess(likedContractsResp) &&
            likedContractsResp.value.user_like_nft_contract.length > 0) ||
          recentlySearchedCollections.length > 0 ? (
            <Container>
              {recentlySearchedCollections.length > 0 && (
                <Section title={t('Recent')}>
                  <div className="grid w-full grid-cols-searchbar-result gap-y-2 p-2">
                    {recentlySearchedCollections
                      .slice(0, 12)
                      .map((contract) => (
                        <ContractItem
                          contract={contract}
                          liked={likedContractIds.has(contract.id)}
                          onClick={() => {
                            saveToRecentlySearchedCollections(contract)
                            const url = getCollectionURL(contract)
                            router.push(url)
                            setActive(false)
                            setText('')
                          }}
                        />
                      ))}
                  </div>
                </Section>
              )}
              {RD.isSuccess(likedContractsResp) &&
                likedContractsResp.value.user_like_nft_contract.length > 0 && (
                  <Section title={t('Collections')}>
                    <div className="grid w-full grid-cols-searchbar-result gap-y-2 p-2">
                      {likedContractsResp.value.user_like_nft_contract
                        .slice(0, 12)
                        .map(({ contract }) => (
                          <ContractItem
                            contract={contract}
                            liked={likedContractIds.has(contract.id)}
                            onClick={() => {
                              saveToRecentlySearchedCollections(contract)
                              const url = getCollectionURL(contract)
                              router.push(url)
                              setActive(false)
                              setText('')
                            }}
                          />
                        ))}
                    </div>
                  </Section>
                )}
            </Container>
          ) : null
        ) : (
          <Container>
            {RD.isSuccess(suggestionsResp) &&
              (suggestionsResp.value.contracts.length +
                suggestionsResp.value.users.length +
                suggestionsResp.value.nfts.length >
              0 ? (
                <div className="flex flex-col">
                  {suggestionsResp.value.contracts.length > 0 && (
                    <Section title={t('Collections')}>
                      <Grid>
                        {suggestionsResp.value.contracts
                          .slice(0, 12)
                          .map((contract) => (
                            <ContractItem
                              contract={contract}
                              liked={likedContractIds.has(contract.id)}
                              onClick={() => {
                                saveToRecentlySearchedCollections(contract)
                                const url = getCollectionURL(contract)
                                router.push(url)
                                setActive(false)
                                setText('')
                              }}
                            />
                          ))}
                      </Grid>
                    </Section>
                  )}
                  {suggestionsResp.value.users.length > 0 && (
                    <Section title={t('Users')}>
                      <Grid>
                        {suggestionsResp.value.users.map((user) => {
                          // TODO: Use button instead
                          return (
                            <li
                              key={user.id}
                              className="hover::bg-gray-200 flex h-16 cursor-pointer select-none items-center gap-3 rounded-full px-3"
                              onClick={() => {
                                const url = userURL(user.address)
                                router.push(url)
                                setActive(false)
                                setText('')
                              }}
                            >
                              <Avatar className="h-10 w-10" data={user} />
                              <div className="flex min-w-0 items-center gap-2">
                                <p className="ts-body-3 truncate break-all font-bold text-gray-900">
                                  {user.nickname ?? fmtEthAddr(user.address)}
                                </p>
                                {user.verified && (
                                  <UserVerifiedBadge className="h-5 w-5" />
                                )}
                              </div>
                            </li>
                          )
                        })}
                      </Grid>
                    </Section>
                  )}
                  {suggestionsResp.value.nfts.length > 0 && (
                    <Section title={t('Items')}>
                      <Grid>
                        {suggestionsResp.value.nfts.slice(0, 4).map((nft) => (
                          <NftItem
                            nft={nft}
                            onClick={() => {
                              const url = detailURL({
                                networkId: nft.network_id,
                                tokenContract: nft.contract.contract,
                                tokenId: nft.token_id,
                              })
                              router.push(url)
                              setActive(false)
                              setText('')
                            }}
                          />
                        ))}
                      </Grid>
                    </Section>
                  )}
                </div>
              ) : (
                <Empty label={t('No Results Found')} />
              ))}
          </Container>
        ))}
    </div>
  )
}

const Container: FC = ({ children }) => (
  <div className="fixed left-4 top-[72px] right-4 z-[3] max-h-[480px] overflow-y-auto rounded-xl border bg-modal-bg shadow-light-1 dark:shadow-dark-1 sm:absolute sm:top-[88px] sm:right-0 sm:left-0">
    {children}
  </div>
)

const Section = ({ title, children }: PropsWithChildren<{ title: string }>) => (
  <section className="flex flex-col">
    <header className="ts-hairline-3 border-b py-3 px-4 text-gray-500">
      {title}
    </header>
    {children}
  </section>
)

const Grid: FC = ({ children }) => (
  <div className="grid w-full grid-cols-searchbar-result gap-y-2 p-2">
    {children}
  </div>
)

const ContractItem = ({
  contract,
  liked,
  onClick,
}: {
  contract: ContractOfContractItem
  liked: boolean
  onClick: () => void
}) => {
  const { t } = useTranslation()
  const ufoImage = useUFO()

  return (
    <li
      className="flex h-16 cursor-pointer select-none items-center space-x-3 rounded-full px-3 hover:bg-gray-200"
      key={contract.id}
      onClick={onClick}
    >
      <Img
        className="h-10 w-10 shrink-0 rounded-full"
        src={resizedCdnUrl(contract.icon_url ?? ufoImage, 128)}
      />
      <div className="flex min-w-0 flex-1 flex-col">
        <div className="flex min-w-0 items-center space-x-1">
          <p className="ts-body-3 truncate font-bold text-gray-900">
            {contract.name}
          </p>
          {contract.verified && (
            <VerifiedFilled className="h-5 w-5 text-primary-1" />
          )}
          {liked && <HeartFilled className="h-4 w-4 text-primary-3" />}
        </div>
        <p className="ts-caption-2 text-gray-500">
          {t('{{count}} items', { count: contract.stats?.total_supply ?? 0 })}
        </p>
      </div>
    </li>
  )
}

const NftItem = ({
  nft: { contract, metadata, ...nft },
  onClick,
}: {
  nft: SearchByKeywordResp['nfts'][0]
  onClick: () => void
}) => {
  const meta = extractNftMetadata(metadata?.meta)
  const name = itemDisplayName(meta, contract.name, nft.token_id)

  return (
    <li
      className="flex h-16 cursor-pointer select-none items-center space-x-3 px-3 hover:bg-gray-200"
      key={nft.id}
      onClick={onClick}
    >
      <NftImage
        className="h-10 w-10 shrink-0"
        {...metadataAssetsAsNftImageProps(
          extractNftMetadataAssets(meta, contract.display_options),
        )}
        imageAlt={name}
      />
      <div className="flex min-w-0 flex-1 flex-col">
        <p className="ts-body-3 truncate font-bold text-gray-900">{name}</p>
        <div className="flex min-w-0 items-center space-x-1">
          <p className="ts-caption-2 truncate text-gray-500">{contract.name}</p>
          {contract.verified && (
            <VerifiedFilled className="h-5 w-5 text-primary-1" />
          )}
        </div>
      </div>
    </li>
  )
}
